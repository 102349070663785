import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Box, Container, Heading, Text } from "theme-ui"
import Breadcrumbs from "../components/breadcrumbs"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  renderRule,
} from "datocms-structured-text-utils"
import { MagicLink } from "../utils/magicLink"
import { ChevronRight } from "react-feather"
import { getPagePath } from "../utils/path"
import { InboundLink, OutboundLink } from "../components/link"
import { Parallax } from "react-parallax"

const PageHero = ({ page, image, home = false }) => (
  <Box
    sx={{
      position: "relative",
      backgroundColor: "text",
      overflow: "hidden",
      zIndex: 0,
      minHeight: ["auto","auto","650px"],

    }}
  >
    {image && (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "120%",
          width: "100%",
          zIndex: -1,
          ".gatsby-image-wrapper": { width: "100%", height: "100%" },
          overflow: "hidden",
        }}
      >
        {image.gatsbyImageData ? (
          <GatsbyImage image={image.gatsbyImageData} />
        ) : (
          <Box
            sx={{
              height: "100%",
              position: "relative",
              "&:after": {
                content: "''",
                position: "absolute",
                bottom: 0,
                left:0,
                width: "100%",
                height:  "100%",
                backgroundColor: "black",
                opacity: .6,
                zIndex: 2,
              },
            }}
          >
            <video
              className="videoTag"
              autoPlay
              loop
              muted
              style={{ height: "100%" }}
            >
              <source src={image.url} type="video/mp4" />
            </video>
          </Box>
        )}
      </Box>
    )}
    <Container
      sx={{
        py: ["10rem", "15rem", !home ? "23rem" : "22rem"],
        px: [3],
        pb: [!home ? "5rem" : "16rem",!home ? "5rem" : "16rem",!home ? "0rem" : "16rem"],
      }}
      variant="xl"
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          height: "90%",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <StructuredText
          data={page.body}
          customRules={[
            renderRule(
              isHeading,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Heading
                        as={`h${node.level}`}
                        variant={`h${node.level}${home ? "home" : ""}`}
                        sx={{ mb: 3, color: "light" }}
                      >
                        {children}
                        <Text variant="underPageTitle">
                          {page.title && page.title.length > 10
                            ? page.title.split(" ")[0]
                            : page.title}
                        </Text>
                      </Heading>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
            renderRule(
              isParagraph,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Text
                        as="p"
                        mb={3}
                        sx={{
                          width: ["100%", "50%"],
                          py: [5],
                          lineHeight: "1.25",
                          fontWeight: "300",
                          fontSize: [3],
                          color: "light",
                        }}
                      >
                        {children}
                      </Text>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
          ]}
        />
      </Box>
      {home && page.viewMore && (
        <Box pt={4}>
          <InboundLink
            to={getPagePath(page.viewMore.link, page.locale)}
            sx={{ fontWeight: "bold" }}
            variant="buttons.full.primaryArrow"
          >
            <span>{page.viewMore.anchor}</span>
            <span>
              <ChevronRight />
            </span>
          </InboundLink>
        </Box>
      )}
      <Box sx={{ position: "absolute", bottom: [4]  }}>
        {!home && <Breadcrumbs page={page} />}
      </Box>
    </Container>
  </Box>
)

export default PageHero
